<template>
  <div
    class="checklist-item"
    :class="{ 'checklist-item-checked': isChecked  }"
  >
    <row container :gutter="20">
      <column :xs="2" :md="1" :lg="1">
        <div class="checklist-checkbox">
          <span @click="toggleChecklistItemChecked()" v-if="isChecked" class="checklist-checked">
            <Oval />
          </span>
          <span @click="toggleChecklistItemChecked()" v-else class="checklist-unchecked">
            <Oval />
          </span>
        </div>
      </column>
      <column :xs="10" :md="11" :lg="11">
        <h3>{{ title }}</h3>
        <p>{{ description }}</p>
        <div v-if="image" class="checklist-item_images">
          <img @click="showLightbox" width="60" height="60" :src="image" />
        </div>
      </column>
    </row>
    <Teleport to="body">
      <Lightbox
        :image="lightboxImage"
        :content="title"
        :display="lightboxActive"
        @closeLightbox="hideLightbox"
      >
      </Lightbox>
    </Teleport>
  </div>
</template>
<script>
import Oval from '@/assets/oval.svg';
import Helpers from '@/helpers/helpers';
import { mapGetters } from 'vuex';
import Lightbox from '@/components/Lightbox';


export default {
  name: 'CheckListItem',
  components: {
    Oval,
    Lightbox
  },
  props: {
    checklistItem: Object,
  },
  data() {
    return {
      isChecked: false,
      lightboxActive: false
    };
  },
  computed: {
    ...mapGetters([
      'utilities/storageToken',
    ]),
    title() {
      return this.checklistItem.content.find(item => {
        return item.contentTypeId === 2;
      }).text;
    },
    description() {
      return this.checklistItem.content.find(item => {
        return item.contentTypeId === 1;
      }).text;
    },
    lightboxImage() {
      let content = Object.assign([], this.checklistItem.content);
      
      let imageContent = content.reverse().find((item) => {
        return item.contentTypeId === 5;
      })

      if(imageContent) {
        return `${this.createUrlForCustomSize(imageContent.storageUrl, 'large')}${this['utilities/storageToken']}`;
      }
      
      return false;
    },
    image() {
      let content = Object.assign([], this.checklistItem.content);
      
      let imageContent = content.reverse().find((item) => {
        return item.contentTypeId === 5;
      })

      if(imageContent) {
        return `${this.createUrlForCustomSize(imageContent.storageUrl, 'small')}${this['utilities/storageToken']}`;
      }
      
      return false;
    }
  },
  methods: {
    createUrlForCustomSize: Helpers.createUrlForCustomSize,
    toggleChecklistItemChecked() {
      this.isChecked = !this.isChecked;
    },
    showLightbox() {
      this.lightboxActive = true
    },
    hideLightbox() {
      this.lightboxActive = false
    }
  },
};
</script>
<style lang="scss" scoped>
.checklist-item {
  background-color: white;
  border-radius: 12px;
  margin-bottom: 1rem;
  padding: 2rem;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  h3 {
    margin-bottom: 1rem;
    font-weight: 500;
  }
  @media (max-width: $breakpoint-mobile) {
    padding: 1rem;
    p {
      margin: 0;
    }
    h3 {
      margin-bottom: .5rem;
    }
  }
  &_images {
    margin-top: 1rem;
  }
  .checklist-checkbox {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.5rem;
    filter: drop-shadow(0 4px 12px $light-grey);
    .checklist-checked,
    .checklist-unchecked {
      cursor: pointer;
    }
    // .checklist-checked {
    //     filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.1));
    // }
    .checklist-checked svg {
      fill: #1aa786;
      stroke: #a5b1ab;
    }
    .checklist-check--mark {
      position: relative;
      height: 0;
      width: 100%;
      padding: 0;
      padding-bottom: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .checklist-checked--mark svg {
      fill: #1aa786;
      stroke: white;
      position: absolute;
      height: 20%;
      width: 20%;
      left: 36%;
      top: 47%;
      transform: translate(-50%, -50%);
    }
  }
}
.checklist-item-checked {
  background-color: #c5e3dc;
}
</style>
