<template>
  <section class="cabin-checklist">
    <row container :gutter="20">
      <column :xs="12" :md="12" :lg="12">
        <h3>{{ checklistDescription }}</h3>
        <div v-if="checklist" class="checklist-items">
          <ChecklistItem
            v-for="(item, index) in checklist.checklistItems"
            :key="index"
            :checklistItem="item"
          >
          </ChecklistItem>
        </div>
        <Button
          @click="completeChecklist(checklist.id)"
          id="finishChecklistButton"
          size="full"
          text="Send inn sjekkliste"
          color="green"
        ></Button>
      </column>
    </row>
  </section>
</template>
<script>
import Button from '@/components/Button';
import ChecklistService from '@/services/ChecklistService';
import ChecklistItem from '@/components/CheckListItem';

export default {
  name: 'checklist',
  components: {
    ChecklistItem,
    Button
  },
  data() {
    return {
      checklist: undefined
    }
  },
  methods: {
    completeChecklist(id) {
      this.checklist.checklistItems.forEach((item) => {
        item.checklistItemStatusId = 1;
      });
      ChecklistService.addChecklistHistory(id, this.checklist.content, this.checklist.checklistItems).then( async (res) => {        
        console.log(res);
        this.$toast.success(`Sjekkliste fullført`);
        this.$router.push({ name: 'checklists'})
      }).catch(err => {
        this.$toast.error(`Kunne ikke fullføre sjekkliste`);
        console.log(err);
      })
    },
    isCabinOwner() {
      if (this.cabinUserRoleId === 1) return true;

      return false;
    },
    isCabinAdministrator() {
      if (this.cabinUserRoleId === 2) return true;

      return false;
    },
    isCabinUser() {
      if (this.cabinUserRoleId === 3) return true;

      return false;
    },
  },
  computed: {
    checklistDescription() {
      if(!this.checklist) return '';

      if(this.checklist.content.length > 0) {
        return this.checklist.content.find(item => {
          return item.contentTypeId === 1;
        }).text
      }

      return '';
    }
  },
  async beforeRouteEnter(to, from, next) {
    const { data } = await ChecklistService.getChecklist(to.params.checklistId);
    next((vm) => {
      if (data) {
        vm.checklist = data;
      }
    });
  }
};
</script>
<style lang="scss" scoped>
 .cabin-checklist {
    @include gutter-padding;
    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 6rem;
    }
    @media (min-width: $breakpoint-mobile) {
      margin: 4rem 0;
    }
  }
</style>