<template>
  <div class="image-lightbox" :class="display ? 'visible' : ''">
    <button class="close-button" @click="closeLightbox">&times;</button>
    <div class="image-lightbox_image">
      <img :src="image" />
    </div>
    <div class="image-lightbox_content">
      {{ content }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  props: {
    image: String,
    content: String,
    display: Boolean
  },
  emits: ['closeLightbox'],
  methods: {
    closeLightbox() {
      this.$emit('closeLightbox', true);
    }
  }
}
</script>
<style lang="scss" scoped>
  .image-lightbox {
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99999;
    background-color: rgba(#000, 0.9);
    &_content {
      margin-top: 1rem;
      color: white;
    }
  }
  .image-lightbox.visible {
    display: flex;
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 40px;
  }
</style>